import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, RecaptchaVerifier } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDjMwLec7fsRqArcUfl04Efj6qEfAvs6u0",
    authDomain: "mobile-igic.firebaseapp.com",
    projectId: "mobile-igic",
    storageBucket: "mobile-igic.appspot.com",
    messagingSenderId: "132422699824",
    appId: "1:132422699824:web:bde1fe629c189c5666d6f5",
    measurementId: "G-2H1YF3H8BF"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app)
// export const recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
//     'size': 'invisible',
//     'callback': (response) => {
//         console.log(response, 'reeeeeeeeeeeeeeeeeeeeeeeee');
//         // reCAPTCHA solved, allow signInWithPhoneNumber.
//         //   onSignInSubmit();
//     }
// });
export default app;