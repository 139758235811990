import React, { Component, useEffect, useState } from "react";
import { validate } from "gerador-validador-cpf";
import {
  Button,
  Input,
  FormGroup,
  Label,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Nav,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { registerLocale } from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { format, isAfter, parseISO } from "date-fns";
import InputMask from "react-input-mask";
import { URL_PreScheduling } from "../Services/preSchedulingService";
import { FaCheck } from "react-icons/fa";
import { IoAlertCircleOutline } from "react-icons/io5";
import { Spinner } from "../../common/components/spinner/spinner";
import { useNavigate } from "react-router-dom";
import characterRemover from "character-remover";
import { FaChevronLeft } from "react-icons/fa";
import "../../App.css";
import { TbAlertCircle } from "react-icons/tb";
import { CustomModal } from "../../common/components/utils/customModal";

export default class FormPayment extends Component {
  constructor(props) {
    AddLibrary();
    super(props);
    registerLocale("br", br);
    const myParam = window.location.pathname.split("/")[1];
    this.state = {
      activeTab: "1",
      modelCard: { numberCard: "", cvv: "", date: "", name: "" },
      errors: {},
      loading: false,
      tooltipOpen: false,
      modelScheduling: { preSchedulingServiceProvideds: [], value: 0, partialValue: false },
      myParam: myParam,
      redirect: false,
      modelPix: {
        galaxPayId: 0,
        reference: "",
        qrCode: "",
        image: "",
        createdAt: "",
      },
      loadingSaveWithPix: false,
      alert: { description: '', type: '', show: false }
    };

  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    if (tab == 2) {
      this.generateQrcode();
    }
  }

  generateQrcode = async () => {
    this.setState({ loading: true });
    const { modelCard, myParam, modelScheduling } = this.state;

    modelScheduling.creditCardRequest = modelCard;
    modelScheduling.method = "pix";
    await axios
      .post(
        `${URL_PreScheduling}/SavePaymentScheduling/${myParam}`,
        modelScheduling
      )
      .then((resp) => {
        const { data } = resp;
        if (data.message != null)
          this.setState({ alert: { description: data.message, type: AlertType.warnining, show: true } })


        else this.setState({ modelPix: data.pixData, loading: false });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };

  setValues = (e, field) => {
    const { modelCard } = this.state;
    modelCard[field] = e.target.value;
    this.setState({ modelCard });
  };

  validate = () => {
    const { modelCard, modelScheduling } = this.state;
    const errors = {};
    let isError = 0;
    const numberCard = characterRemover.removeAll(modelCard.numberCard);
    const date = characterRemover.removeAll(modelCard.date);
    const cpf = characterRemover.removeAll(modelScheduling.document);
    if (!cpf || validate(cpf) == false
    ) {
      isError++;
      errors.documentError = true;
    } else errors.documentError = false;
    if (!modelCard.name) {
      isError++;
      errors.nameError = true;
    } else errors.nameError = false;

    if (!numberCard || numberCard.length < 16) {
      isError++;
      errors.numberCardError = true;
    } else errors.numberCardError = false;

    if (!date || date.length < 6) {
      isError++;
      errors.dateError = true;
    } else errors.dateError = false;

    if (!modelCard.cvv) {
      isError++;
      errors.cvvError = true;
    } else errors.cvvError = false;
    this.setState({
      errors: errors,
    });
    return isError;
  };

  toggleClick = () => {
    const { modelPix } = this.state;
    this.setState({
      tooltipOpen: true,
    });

    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(modelPix.qrCode);
    }
  };

  payment = async () => {
    if (this.validate() == 0) {
      const { modelCard } = this.state;
      const token = localStorage.getItem("PublicTokenGalax");
      const inst = new window.GalaxPay(token, true);
      let card = "";
      try {
        card = await inst.newCard({
          number: modelCard.numberCard,
          holder: modelCard.name,
          expiresAt: `${modelCard.date.slice(-4)}-${modelCard.date.substring(
            0,
            2
          )}`,
          cvv: modelCard.cvv,
        });
      } catch (error) {
        this.setState({ alert: { description: "Verifique as informações do cartão!", type: AlertType.warnining, show: true } })
        return;
      }
      
      new Promise((resolve,reject) => {
        this.setState({ loading: true });
        inst.hashCreditCard(
          card,
          function (hash) {
            if (hash) resolve(hash);
          },
          function (error) {
         reject(error)
          }
        );
      }).then(async (value) => {
        const { myParam, modelScheduling } = this.state;
        modelScheduling.hashCard = value;
        modelScheduling.method = "creditcard";
        modelScheduling.document = characterRemover.removeAll(modelScheduling.document)
       
        await axios
          .post(
            `${URL_PreScheduling}/SavePaymentScheduling/${myParam}`,
            modelScheduling
          )
          .then((resp) => {
            const { data } = resp;
            if (data.message == "Salvo com sucesso!")
              this.setState({ redirect: true, loading: false });
            else
              this.setState({ alert: { description: data.message, type: AlertType.warnining, show: true } })
          })
          .catch((error) => {
            this.setState({ loading: false });
          });

        this.setState({ loading: false });
      }).catch((e) =>{ console.log(e,'reject', );
        this.setState({ alert: { description: "Verifique as informações digitadas!", type: AlertType.warnining, show: true },loading: false  })
      }
      );
    }
  };

  SearchPaymentAndSaveSchedule = async () => {
    // bater na api se achar o pagamento salva o agendamento
    this.setState({ loading: true });
    const { modelPix, myParam, modelScheduling } = this.state;

    modelScheduling.pixRequest = modelPix;
    await axios
      .post(
        `${URL_PreScheduling}/SearchPaymentAndSaveSchedule/${myParam}`,
        modelScheduling
      )
      .then((resp) => {
        const { data } = resp;
        if (data.message == "Salvo com sucesso!")
          this.setState({ redirect: true, loading: false });
        else {
          this.setState({ alert: { description: data.message, type: AlertType.warnining, show: true } })
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };

  componentDidMount = () => {
    let modelScheduling = JSON.parse(localStorage.getItem("mapscheduling"));
    this.setState({
      modelScheduling: modelScheduling,
    });
  }

  clearAlert = () => {
    this.setState({ alert: { description: '', type: '', show: false } })
  }

  render() {
    const {
      errors,
      loading,
      tooltipOpen,
      modelScheduling,
      redirect,
      myParam,
      modelPix,
      alert
    } = this.state;
    return (
      <div>
        {
          alert.show &&
          <ShowAlert
            alert={alert}
            clearAlert={this.clearAlert}
          />}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            alignSelf: "stretch",
            marginBottom: '24px'
          }}
        >
          <button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              backgroundColor: "transparent", // Ghost button, no background
              border: "1px", // Dark Blue color for border
              color: "#003366", // Dark Blue color for icon
              borderRadius: "4px 0 0 0",
              opacity: 1, // Assuming opacity 0px in the design is a typo
              cursor: "pointer",
              radius: "6px",
              gap: "8px",
              paddingTop: "9px",
              paddingRight: "16px",
              paddingBottom: "9px",
              paddingLeft: "16px",
            }}
            onClick={() => window.history.back()} // Replace with your actual event handler
          >
            <FaChevronLeft size={12} />
          </button>
          <div
            style={{
              flex: "1 0 0",
              fontWeight: "600",
              fontSize: "18px",
              fontFamily: "inter",
              textAlign: "left",
              margin: 0, // Remove default margin
              padding: "8px",
              fontFamily: 'inter,sans-serif'
            }}
          >
            Escolha sua forma de pagamento
          </div>
        </div>
        {redirect &&
          (
            <Redirect myParam={myParam} tel={modelScheduling.telephone} posScheduling={modelScheduling.posScheduling} />
          )}
        {loading && <Spinner />}
        <div>
          <Nav
            pills
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "stretch",
              marginBottom: "6px",
              marginTop: "6px",
              radius: "9px",
              backgroundColor: "var(--Color-White-75, #FBFBFB)",
            }}
          >
            <NavItem
              style={{
                display: "flex",
                flex: "1 0 0",
                minHeight: "35px",
                maxHeight: "35px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
              }}
            >
              <NavLink
                active={this.state.activeTab === "1" ? true : false}
                onClick={() => {
                  this.toggle("1");
                }}
                style={{
                  backgroundColor:
                    this.state.activeTab === "1"
                      ? "white"
                      : "var(--Color-White-75, #FBFBFB)",
                  color:
                    this.state.activeTab === "1"
                      ? "black"
                      : "var(--color-black-15, rgba(0, 0, 0, 0.15))",

                  display: "flex",
                  flex: "1 0 0",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  boxShadow: this.state.activeTab === "1" ?
                    "0px 1px 2px 0px rgba(18, 55, 105, 0.08), 0px 0px 0px 1px rgba(9, 25, 72, 0.13)" : '',
                  borderColor: 'red',
                  fontFamily: "inter, sans-serif",
                  fontWeight: "500",
                }}
              >
                Cartão de Crédito
              </NavLink>
            </NavItem>
            {" "}
            <NavItem
              style={{
                display: "flex",
                flex: "1 0 0",
                minHeight: "35px",
                maxHeight: "35px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
              }}
            >{" "}
              <NavLink
                active={this.state.activeTab === "2" ? true : false}
                onClick={() => {
                  this.toggle("2");
                }}
                style={{
                  backgroundColor:
                    this.state.activeTab === "2"
                      ? "white"
                      : "var(--Color-White-75, #FBFBFB)",
                  color:
                    this.state.activeTab === "2"
                      ? "black"
                      : "var(--color-black-15, rgba(0, 0, 0, 0.15))",

                  display: "flex",
                  flex: "1 0 0",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "6px",
                  boxShadow: this.state.activeTab === "2" ?
                    "0px 1px 2px 0px rgba(18, 55, 105, 0.08), 0px 0px 0px 1px rgba(9, 25, 72, 0.13)" : "",
                  fontFamily: "inter, sans-serif",
                  fontWeight: "500",
                  marginLeft: '2px'
                }}
              >
                Pix
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            activeTab={this.state.activeTab}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
              alignSelf: "stretch",
              paddingTop: "24px",
            }}
          >
            <TabPane className="custom-tab-pane" tabId="1">
              <div
                className="row"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0px",
                  alignSelf: "stretch",
                }}
              >
                <div style={{ marginBottom: '8px ' }}>
                  <Label style={{ fontFamily: 'inter, sans-serif', fontSize: "14px", margin: 0 }}>Nome do Titular do Cartão</Label>
                  <Input
                    type="text"
                    onChange={(e) => this.setValues(e, "name")}
                    placeholder="Digitar...."
                    invalid={errors.nameError}
                  ></Input>
                  <FormFeedback>Campo obrigatório</FormFeedback>
                </div>

                <div style={{ marginBottom: '8px ' }}>
                  <Label style={{ fontFamily: 'inter, sans-serif', fontSize: "14px", margin: 0 }}>Número do Cartão</Label>
                  <Input
                    type="text"
                    mask="9999 9999 9999 9999"
                    tag={InputMask}
                    onChange={(e) => this.setValues(e, "numberCard")}
                    placeholder="0000.0000.0000.0000"
                    invalid={errors.numberCardError}
                  ></Input>
                  <FormFeedback>Campo obrigatório</FormFeedback>
                </div>
                <div style={{ marginBottom: '8px ' }}>
                  <Label style={{ fontFamily: 'inter, sans-serif', fontSize: "14px", margin: 0 }}>CPF do Titular</Label>
                  <Input
                    type="text"
                    mask="999.999.999-99"
                    tag={InputMask}
                    onChange={(e) => this.setState({ ...modelScheduling["document"] = e.target.value })}
                    placeholder="000.000.000-00"
                    invalid={errors.documentError}
                  ></Input>
                  <FormFeedback>Campo obrigatório</FormFeedback>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    gap: "12px",
                    margin: "0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "1px",
                      flex: "1 0 0",
                      marginBottom: "8px"
                    }}
                  >
                    <Label style={{ fontFamily: 'inter, sans-serif', fontSize: "14px", margin: 0 }} >Validade</Label>
                    <Input
                      mask="**-****"
                      tag={InputMask}
                      placeholder="**/****"
                      onChange={(e) => this.setValues(e, "date")}
                      invalid={errors.dateError}
                    ></Input>
                    <FormFeedback>Campo obrigatório</FormFeedback>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "1px",
                      flex: "1 0 0",
                      marginBottom: "8px"
                    }}
                  >
                    <Label style={{ fontFamily: 'inter, sans-serif', fontSize: "14px", margin: 0 }}>CVV/CVC</Label>
                    <Input
                      type="number"
                      placeholder="***"
                      invalid={errors.cvvError}
                      onChange={(e) => this.setValues(e, "cvv")}
                    ></Input>
                    <FormFeedback>Campo obrigatório</FormFeedback>
                  </div>
                </div>
                <FormGroup
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "1px",
                    flex: "1 0 0",
                  }}
                >
                  <Label style={{ fontFamily: 'inter, sans-serif', fontSize: "14px", margin: 0 }}>{modelScheduling.partialValue ? "Valor Parcial" : "Valor Total"}</Label>
                  <Input
                    value={modelScheduling.value.toLocaleString('pt-br',
                      { style: 'currency', currency: 'BRL' })}
                    disabled={true}
                  />
                </FormGroup>
              </div>
              <Button block color="dark"
                onClick={(e) => this.payment()}
                style={{
                  width: "100%",
                  fontWeight: "600",
                }
                }
                >Pagar</Button>
            </TabPane>

            <TabPane tabId="2" className="custom-tab-pane">
              {loading == false && modelPix.galaxPayId > 0 && (
                <div>
                  {isAfter(new Date(), parseISO(modelPix.createdAt, new Date())) == true
                    ? (
                      <div className="row">
                        <Button
                          style={{
                            borderRadius: '4px',
                            border: '1px solid var(--Color-Gray-100, #E3E3E3)',
                            display: 'flex',
                            padding: '8px 12px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            alignSelf: 'stretch',
                            backgroundColor: '#FAFAFA',
                            color: 'black'
                          }}
                          onClick={(e) => this.generateQrcode()}
                        >
                          Atulizar QR code
                        </Button>
                      </div>) :
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          alignSelf: "stretch",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            alignSelf: "stretch",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              borderRadius: "8px",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={modelPix.image}
                              loading="lazy"
                              alt=""
                              style={{
                                width: "250px",
                                height: "250px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="row" >
                          <p style={{
                            fontFamily: 'inter, sans-serif',
                            marginTop: '16px',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                          }}>
                            Expira em:
                            {" " +
                              format(
                                parseISO(modelPix.createdAt, new Date()),
                                "HH:mm"
                              )}
                          </p>
                        </div>
                        <div className="row">
                          <p style={{
                            fontFamily: 'inter, sans-serif',

                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                          }}>
                            Identificador:
                            {" " + modelPix.reference}
                          </p>
                        </div>
                        <div
                          style={{
                            flex: 1,
                            position: 'relative',
                            fontWeight: 600
                          }}
                          className="row">
                          <button
                            style={{
                              borderRadius: '4px',
                              border: '1px solid var(--Color-Gray-100, #E3E3E3)',
                              display: 'flex',
                              padding: '8px 12px',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '10px',
                              alignSelf: 'stretch',
                              backgroundColor: '#FAFAFA',
                              width: '226px',
                            }}
                            onClick={(e) => this.toggleClick()}
                          >
                            {tooltipOpen ? (
                              <div
                                style={{
                                  color: 'var(--Color-Primary-500, #171821)',
                                  textAlign: 'center',
                                  fontFamily: 'Inter, sans-serif',
                                  fontSize: '16px',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                Copiado
                              </div>
                            ) : (
                              <div>

                                Copiar código Pix
                              </div>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="row"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "4px",
                          flex: "1 0 0",
                          marginTop: '24px',
                          borderRadius: '4px'
                        }}
                      >
                        <div className="col" >
                          <Label style={{ fontFamily: 'inter, sans-serif', fontSize: "14px", margin: 0 }}>{modelScheduling.partialValue ? "Valor Parcial" : "Valor Total"}</Label>
                          <Input
                            value={modelScheduling.value.toLocaleString('pt-br',
                              { style: 'currency', currency: 'BRL' })}
                            disabled={true}
                          />
                        </div>

                      </div>
                      <Button
                        color="dark"
                        block
                        onClick={(e) => this.SearchPaymentAndSaveSchedule()}
                        disabled={loading}
                        style={{ marginTop: '16px' }}
                      >
                        {<span style={{ fontFamily: 'inter,sans-serif' }}> Confirmar pagamento</span>}
                      </Button>

                    </div>
                  }
                </div>
              )}
              {loading == false && modelPix.galaxPayId == 0 && (
                <div className="text-center">
                  <IoAlertCircleOutline fontSize={40} color="orange" /> Pix
                  indisponível no momento
                </div>
              )}
              {loading && ""}
            </TabPane>
          </TabContent>
        </div>
      </div>
    );
  }
}

function Redirect(props) {
  const navigate = useNavigate();

  const redirectSearchScheduling = () => {
    navigate("/" + props.myParam, {
      replace: true,
    });
  };
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    setShowAlert(true);
    const timer = setTimeout(() => {
      console.log('Effect executed after 3 seconds');
      setShowAlert(false);
      redirectSearchScheduling();
    }, 4000); // 3000ms = 3 segundos

    // Cleanup: Limpa o timeout se o componente desmontar ou se as dependências mudarem
    return () => clearTimeout(timer);
  }, [showAlert]);
  return (
    <div>{
      <CustomModal
        description={props.posScheduling ? 'Pagamento realizado!' : 'Agendamento realizado!'}
        icon={<FaCheck />}
        backdrop={'true'}
        background='#F2FFF2'
        isOpen={showAlert}
      />}
    </div>
  );
}

export function AddLibrary() {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://js.galaxpay.com.br/checkout.min.js";
  script.async = true;
  document.body.appendChild(script);
}
function ShowAlert(props) {
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    setShowAlert(true);
    const timer = setTimeout(() => {
      console.log('Effect executed after 3 seconds');
      setShowAlert(false);
      props.clearAlert();
    }, 3000); // 3000ms = 3 segundos

    // Cleanup: Limpa o timeout se o componente desmontar ou se as dependências mudarem
    return () => clearTimeout(timer);
  }, [showAlert]);
  return (
    <div>{
      <CustomModal
        description={props.alert.description}
        icon={<TbAlertCircle />}
        backdrop={'true'}
        background='#FFF9EE'
        isOpen={showAlert}
      />}
    </div>
  );
};

const AlertType = {
  warnining: 'warnining',
  sucess: 'sucess',
  error: 'error'
};