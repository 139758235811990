import React, { Component } from 'react'
import axios from 'axios';
import { URL_PreScheduling } from '../Services/preSchedulingService';
import { FiAlertTriangle } from 'react-icons/fi'
export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        const myParam = window.location.pathname.split('/')[1];
        this.state = { hasError: false, myParam: myParam, establishmentBlocked: false };
    }

    componentDidMount = async () => {
        await this.consultClinic()
    }

    consultClinic = async () => {
        const { myParam } = this.state
        if (myParam != '') {

            await axios.get(`${URL_PreScheduling}/GetClinicById/${myParam}`).then(resp => {
                const { data } = resp
                if (data == 'Clínica não localizada!') {
                    this.setState({ hasError: true })
                } else if (data == "Empresa desativada.") {
                    this.setState({ establishmentBlocked: true })
                }
            }).catch(() => { this.setState({ hasError: true }) })
        }
    }
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        })
        // You can also log error messages to an error reporting service here

    }
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    render() {
        const { clinicLocated } = this.props

        if (this.state.hasError) {
            return (
                <div className="abs-center wd-xl">
                    <div className="text-center mb-4">
                        <div className="text-lg mb-3 mt-3">
                            <FiAlertTriangle size={50} color="orange" />
                        </div>
                        <p className="lead m-0">A Empresa que você está tentando acessar não foi encontrada neste site.</p>
                        <p className="lead m-0"> Certifique-se de que a URL está correta.</p>
                        <p>Empresa não encontrada.</p>
                    </div>

                    <div className="p-3 text-center">
                        <span className="mr-2">&copy;</span>
                        <span>2022</span>
                        <span className="mx-2">-</span>
                        <span>Igic</span>
                        <br />
                    </div>
                </div>
            );
        } else if (this.state.establishmentBlocked) {
            return (
                <div className="abs-center wd-xl">
                    <div className="text-center mb-4">
                        <div className="text-lg mb-3 mt-3">
                            <FiAlertTriangle size={50} color="orange" />
                        </div>
                        <p className="lead m-0"> A empresa que você está tentando acessar foi desativada temporariamente.</p>
                        <p>Empresa desativada.</p>
                    </div>
                    <div className="p-3 text-center">
                        <span className="mr-2">&copy;</span>
                        <span>2022</span>
                        <span className="mx-2">-</span>
                        <span>Igic</span>
                        <br />
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}